
<style scoped lang="less">
.t_answer_AddImportDialog {
  .notice_title {
    font-weight: bold;
    padding: 0 10px;
    margin: 10px 0;
  }
  .notice {
    padding: 0 10px;
    li {
      margin-bottom: 5px;
    }
  }
  .upload-demo {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
<template>
  <div>
    <el-dialog
      :visible.sync="dialog"
      title="导入题目"
      custom-class="t_answer_AddImportDialog"
      width="40%"
    >
      <el-button @click="download" type="primary">下载模板</el-button>
      <div class="notice_title">注意事项</div>
      <ul class="notice">
        <li>
          1.只支持单选题，多选题，问答题，判断题，名词解释，论述题六种类型
        </li>
        <li>2.多选题答案，请用逗号隔开，如 A,B</li>
        <li>3.上传题目不支持章节知识点的选择，请上传后编辑</li>
        <li>4.不支持含图片题目</li>
      </ul>
      <el-upload
        class="upload-demo"
        ref="upload"
        drag
        :action="`${url}hnjxjy-core/eduCourseQuestion/importCourseQuestions`"
        :data="actionData"
        :headers="{
          'x-token': auth['x-token'],
          'x-perm': auth['x-perm'],
        }"
        :on-success="onSuccess"
        :on-error="onError"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false" type="primary">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { BASE_URL } from "@/libs/domain";
import { ExportMB } from "@/libs/api/teacher/answer";
export default {
  name: "t_answer_AddImportDialog",
  props: ["items"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    url() {
      return BASE_URL;
    },
    actionData() {
      let data = {
        courseId: this.course.id,
        status: this.items ? this.items.key : null,
      };
      return data;
    },
    auth() {
      return this.$store.getters.getAuth;
    },
  },
  methods: {
    download() {
      if (!this.items) {
        return this.$message.error("未选中题目类型");
      }
      ExportMB({ status: this.items.key }).then((res) => {
        // let blob = new Blob([res]);
        let el = document.createElement("a");
        let href = window.URL.createObjectURL(res);
        el.href = href;
        el.download = this.items.label + "模板.xls";
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
        window.URL.revokeObjectURL(href);
      });
    },
    onSuccess(res) {
      this.$refs.upload.clearFiles();
      this.$message.info(res.message);
      this.dialog = false;
      this.$emit("initQuery");
    },
    onError(res) {
      this.$refs.upload.clearFiles();
      this.$message.error(res.message);
    },
  },
};
</script>
