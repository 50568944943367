<style scoped lang="less">
.t_fill {
  .t_fill_ul {
    li {
      background: #f2f2f2;
      padding: 4px 10px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .el-input {
        flex: 1;
        margin: 0 10px;
      }
    }
  }
}
</style>
<template>
  <div class="t_fill">
    <ul class="t_fill_ul">
      <li v-for="(item, index) in data" :key="index">
        <span>第{{ index + 1 }}题</span>
        <el-input
          v-model="item.text"
          placeholder="请输入"
          :maxlength="100"
          @input="onInput"
          show-word-limit
          clearable
        ></el-input>
        <el-button type="success" @click="edit(index)" icon="el-icon-edit">
          编辑
        </el-button>
        <el-button type="success" @click="del(index)" icon="el-icon-delete">
          删除
        </el-button>
      </li>
    </ul>
    <el-button type="success" @click="add">添加空格</el-button>
    <span style="color: #ff0000">请不要手动删除题干中的空格</span>

    <EditorContent ref="editorContentRef" @saveClose="saveClose" />
  </div>
</template>

<script>
import EditorContent from "../EditorContent.vue";
export default {
  name: "t_fill",
  components: { EditorContent },
  props: ["questionKey"],
  data() {
    return {
      data: [],
    };
  },
  watch: {
    questionKey(n) {
      if (n) {
        this.data = [];
        let split = n.split(",");
        if (split.length) {
          split.forEach((item) => {
            this.data.push({ text: item });
          });
        }
      } else {
        this.data = [];
      }
    },
  },
  methods: {
    onInput() {
      this.$emit("optionsInput", this.data);
    },
    edit(index) {
      this.$refs["editorContentRef"].editItem = {
        index,
        content: this.data[index].text,
        dialog: true,
      };
    },
    del(index) {
      if (index !== this.data.length - 1) {
        return this.$message.error("请重最后一题开始删除");
      }
      this.data.splice(index, 1);
      const text = `第${this.data.length}题`;
      this.$emit("title_fill", { index });
    },
    add() {
      this.data.push({ text: "" });
      const text = `第${this.data.length}题`;
      this.$emit("title_fill", { text });
    },

    saveClose({ index, content }) {
      this.data[index].text = content;
      this.$emit("options", this.data);
    },
  },
};
</script>