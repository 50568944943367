<template>
  <div class="t_checked">
    <ul class="select_ul">
      <li v-for="(item, index) in selectOptions" :key="index">
        <div class="select_inner">
          <span>{{ mapper(index + 1) }}.</span>
          <el-checkbox
            v-model="item.checked"
            @change="checkboxChange(index)"
          ></el-checkbox>
          <el-input
            style="min-width: 250px; flex: 1"
            @change="$emit('options', selectOptions)"
            v-model="item.text"
            :maxlength="200"
            show-word-limit
          ></el-input>
        </div>
        <div>
          <el-button
            type="success"
            icon="el-icon-edit"
            @click="edit(item, index)"
          >
            编辑
          </el-button>
          <el-button type="success" icon="el-icon-delete" @click="del(index)">
            删除
          </el-button>
          <el-button type="success" icon="el-icon-bottom" @click="down(index)">
            降序
          </el-button>
          <el-button type="success" icon="el-icon-top" @click="up(index)">
            升序
          </el-button>
        </div>
      </li>
      <el-button icon="el-icon-plus" type="success" @click="addItem">
        添加选项
      </el-button>
    </ul>
    <EditorContent
      ref="editorContentRef"
      :options="selectOptions"
      @saveClose="saveClose"
    />
  </div>
</template>

<script>
import { MAPPER } from "@/tools/const";
import EditorContent from "../EditorContent.vue";
export default {
  name: "t_checked",
  components: { EditorContent },
  props: {
    items: Object,
    eduCourseQuestionOps: Array,
    questionKey: String,
  },
  data() {
    return {
      selectOptions: [
        { text: "", checked: false },
        { text: "", checked: false },
        { text: "", checked: false },
        { text: "", checked: false },
      ],
    };
  },
  watch: {
    items(n) {
      // 编辑
      if (n.key && n.key === "1") {
        // 单选
        this.selectOptions.forEach((item, i) => {
          item.checked = false;
        });
      }
    },
    eduCourseQuestionOps(n) {
      if (n.length) {
        this.selectOptions = [];
        n.forEach((v, i) => {
          if (this.mapper(i + 1) === v.itemKey) {
            this.selectOptions[i] = {};
            this.$set(this.selectOptions, i, {
              text: v.itemVal,
              checked:
                this.questionKey === v.itemVal || this.questionKey === v.itemKey
                  ? true
                  : false,
            });
          }
        });
      } else {
        this.selectOptions.forEach((item, i) => {
          item.checked = false;
        });
      }
    },
  },
  computed: {
    mapper() {
      return (index) => {
        let letter = "";
        for (const key in MAPPER) {
          if (index === MAPPER[key]) {
            letter = key;
            break;
          }
        }
        return letter;
      };
    },
  },
  methods: {
    edit(row, index) {
      this.$refs["editorContentRef"].editItem.index = index;
      this.$refs["editorContentRef"].editItem.content = row.text;
      this.$refs["editorContentRef"].editItem.dialog = true;
    },
    del(index) {
      this.selectOptions.splice(index, 1);
      this.$emit("options", this.selectOptions);
    },
    down(index) {
      const item = this.selectOptions[index];
      const down = this.selectOptions[index + 1];
      if (down) {
        this.$set(this.selectOptions, index, down);
        this.$set(this.selectOptions, index + 1, item);
      }
      this.$emit("options", this.selectOptions);
    },
    up(index) {
      const item = this.selectOptions[index];
      const up = this.selectOptions[index - 1];
      if (up) {
        this.$set(this.selectOptions, index, up);
        this.$set(this.selectOptions, index - 1, item);
      }
      this.$emit("options", this.selectOptions);
    },
    addItem() {
      this.selectOptions.push({ text: "", checked: false });
      this.$emit("options", this.selectOptions);
    },

    checkboxChange(index) {
      if (this.items.key === "1") {
        // 单选
        this.selectOptions.forEach((item, i) => {
          if (i !== index) {
            item.checked = false;
          }
        });
      }
      this.$emit("options", this.selectOptions);
    },
    saveClose({ index, content }) {
      this.selectOptions[index].text = content;
      this.$emit("options", this.selectOptions);
    },
  },
};
</script>

<style lang="less" scoped>
.t_checked {
  .select_ul {
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      background: #f2f2f2;
      padding: 4px 0;
      .select_inner {
        display: inline-flex;
        align-items: center;
        .el-checkbox {
          margin: 0 15px;
        }
      }
    }
  }
}
</style>