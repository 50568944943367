<style lang="less" scoped>
.t_answer_manage {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
    .form {
      margin-top: 20px;
    }
    .date_class,
    .el-input,
    .el-select {
      width: 250px;
    }
  }


}



</style>
<style lang="less">

.suofangimg > p > img{
  width: 100px;
  height: 100px;
}
.t_answer_manage_add_title_ul {
  li {
    cursor: pointer;
    padding: 8px;
    &:hover {
      background: @mainColor;
      color: #f2f2f2;
    }
  }
}
</style>
<template>
  <div class="t_answer_manage">
    <div class="stu-module-header">
      <div class="stu-module-title">题库管理</div>
    </div>
    <div class="content">
      <div class="button_group">
        <el-popover placement="bottom" width="150" trigger="click">
          <ul class="t_answer_manage_add_title_ul">
            <li
              v-for="(item, index) in addTitleData"
              :key="index"
              @click="handleTitleItem(item)"
            >
              {{ item.label }}
            </li>
          </ul>
          <el-button
            slot="reference"
            type="primary"
            icon="el-icon-plus"
            style="margin-right: 10px"
          >
            添加题目
          </el-button>
        </el-popover>
        <el-popover placement="bottom" width="150" trigger="click">
          <ul class="t_answer_manage_add_title_ul">
            <li
              v-for="(item, index) in addTitleData"
              :key="index"
              @click="handleImportItem(item)"
            >
              {{ item.label }}
            </li>
          </ul>
          <el-button
            type="primary"
            slot="reference"
            style="margin-right: 10px"
            icon="el-icon-upload2"
          >
            导入题目
          </el-button>
        </el-popover>
        <el-button
          type="primary"
          icon="el-icon-download"
          @click="downloadTitle"
        >
          导出题目
        </el-button>
        <!-- <el-button type="primary" icon="el-icon-edit" @click="setModule">
          批量修改模块
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-zoom-out"
          @click="clearSyncTitle"
        >
          清理同步题目
        </el-button> -->
        <el-button type="primary" @click="onDel" icon="el-icon-delete">
          删除
        </el-button>
        <el-button type="primary" @click="onStop" icon="el-icon-circle-close">
          禁用
        </el-button>
        <el-button type="primary" @click="onStart" icon="el-icon-circle-check">
          启用
        </el-button>
      </div>
      <el-form
        class="form"
        ref="answerRefForm"
        :model="form"
        size="medium"
        inline
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="题干" prop="questionTitle">
          <el-input
            v-model="form.questionTitle"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="建立时间" prop="startTime">
          <el-date-picker
            class="date_class"
            v-model="createTime"
            type="daterange"
            format="yyyy/MM/dd HH:mm:ss"
            value-format="yyyy/MM/dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="难度" prop="difficultyVal">
          <el-select
            v-model="form.difficultyVal"
            clearable
            placeholder="请选择"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in 10"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="enabled">
          <el-select v-model="form.enabled" clearable>
            <el-option
              v-for="(item, index) in enabledOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="试题类型" prop="questionTypeId">
          <el-select v-model="form.questionTypeId" clearable>
            <el-option
              v-for="(item, index) in typeOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
          <el-button type="primary" @click="reset('answerRefForm')"
            >清空</el-button
          >
        </el-form-item>
      </el-form>

      <el-table
        :data="tableData"
        :height="height"
        v-loading="loading"
        stripe
        @selection-change="selectionChange"
      >
        <el-table-column
          width="65"
          align="center"
          type="selection"
        ></el-table-column>
        <el-table-column label="序号" width="65" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="题干"
          prop="questionTitle"
          width="140"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="suofangimg" v-html="scope.row.questionTitle"></div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in column"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :min-width="item.width"
          :formatter="item.formatter"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" align="center" width="140">
          <template slot-scope="scope">
            <el-button type="primary" @click="edit(scope.row)">编辑</el-button>
            <el-button
              @click="handlePreview(scope.row, scope.$index)"
              type="success"
            >
              预览
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <add-title-dialog
      ref="add_title_ref"
      :rows="rows"
      :items="addTitleItem"
      @onInputData="addTg"
      @initQuery="query"
    />

    <add-import-dialog
      ref="add_import_ref"
      :items="addImportItem"
      @initQuery="query"
    ></add-import-dialog>
    <!-- 预览 -->
    <el-dialog
      :visible.sync="previewItem.dialog"
      :title="previewItem.title"
      width="40%"
    >
      <preview-dialog :id="previewItem.id"></preview-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="previewItem.dialog = false" type="primary">
          关闭
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddTitleDialog from "./module/AddTitleDialog.vue";
import AddImportDialog from "./module/AddImportDialog.vue";
import PreviewDialog from "./module/PreviewDialog.vue";
import {
  QueryEduCourseByTeaId,
  AddEudQuestionAll, // 添加题目
  DeleteEduCourseQuestion, // 批量删除
  ExcelEduQuestion, // 导出 单选1 多选3
  ExcelEduQuestionOther, // 导出 其他类型
  UpdateEduCourseQuestion, // 禁用/启用
} from "@/libs/api/teacher/answer";
export default {
  name: "t_answer_manage",
  components: { AddTitleDialog, AddImportDialog, PreviewDialog },
  data() {
    return {
      height: window.innerHeight - 550,
      loading: false,
      createTime: [], // 建立时间
      form: {
        questionTitle: null, // 题干
        startTime: null, // 开始时间
        endTime: null, // 结束时间
        difficultyVal: "", // 难度
        enabled: "", // 状态，是否启用 0禁用 1启用 ""全部
        questionTypeId: "", // 试题id
      },
      difficultyValOptions: [],
      enabledOptions: [
        { label: "全部", value: "" },
        { label: "启用", value: 1 },
        { label: "禁用", value: 0 },
      ],
      typeOptions: [
        { label: "全部", value: "" },
        { label: "单选题", value: 1 },
        { label: "判断题", value: 2 },
        { label: "多选题", value: 3 },
        { label: "填空题", value: 4 },
        { label: "问答题", value: 5 },
      ],
      // 选择
      selectionData: [],
      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
      column: [
        { label: "创建时间", prop: "createTime", width: 140 },
        { label: "创建者", prop: "teacherName" },
        { label: "类型", prop: "typeName" },
        {
          label: "状态",
          prop: "enabled",
          formatter: (row) => {
            if (row) {
              const find = this.enabledOptions.find(
                (f) => f.value === row.enabled
              );
              return find ? find.label : row.enabled;
            }
            return "-";
          },
        },
        { label: "难度", prop: "difficultyVal" },
      ],
      addTitleItem: null,
      addTitleData: [
        { label: "单项选择题", key: "1" },
        { label: "判断题", key: "2" },
        { label: "多项选择题", key: "3" },
        { label: "填空题", key: "4" },
        { label: "问答题", key: "5" },
      ],
      addImportItem: null,
      rows: null,
      // 预览 - 行信息
      previewItem: {
        dialog: false,
        title: "测试题目",
        id: null,
      },
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    this.query();
  },
  methods: {
    addTg(items, fillData) {
      // 填空题输入项
      if (null != fillData && fillData != "") {
        items.questionKey = fillData.join(",");
      }
      const data = {
        ...items,
        courseId: this.course.id,
        questionTypeId: this.addTitleItem.key, // 题目类型
      };
      this.openLoadingView();
      AddEudQuestionAll(data).then((res) => {
        this.$refs["add_title_ref"].form = {
          difficultyVal: "1", // 难度
          questionTitle: "", // 题干
          questionKey: "", // 答案
          questionAnalyze: null, // 题目解析
        };
        this.$refs["add_title_ref"].fillInputData = [];
        this.$refs["add_title_ref"].questionTitle = [];
        this.$refs["add_title_ref"].json = null;
        this.$refs["add_title_ref"].eduCourseQuestionOps = [];
        this.$refs["add_title_ref"].dialog = false;
        this.query();
        this.loadingView();
      })
      .catch(err => {
        this.loadingView.close();
      })
    },
    query() {
      if (this.createTime && this.createTime.length) {
        this.form.startTime = this.createTime[0];
        this.form.endTime = this.createTime[1];
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
      const p = {
        ...this.form,
        page: this.current,
        pageSize: this.pageSize,
        courseId: this.course.id,
      };
      this.loading = true;
      QueryEduCourseByTeaId(p)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reset(refName) {
      this.$refs[refName].resetFields();
      this.createTime = [];
    },
    selectionChange(val) {
      this.selectionData = val;
    },
    edit(row) {
      if (row) {
        this.addTitleItem = {
          label: row.typeName,
          key: row.questionTypeId + "",
        };
        this.rows = { ...row };
        this.$refs["add_title_ref"].dialog = true;
      }
    },
    // 预览
    handlePreview(row, index) {
      this.previewItem = {
        id: row.id,
        title: row.typeName,
        dialog: true,
      };
    },

    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.query();
      }
    },
    // 添加题目
    handleTitleItem(item) {
      this.addTitleItem = item;
      this.rows = null;
      this.$refs["add_title_ref"].dialog = true;
    },
    // 导入题目
    handleImportItem(item) {
      this.addImportItem = item;
      this.$refs["add_import_ref"].dialog = true;
    },
    // 导出题目
    downloadTitle() {
      const parms = {
        questionTypeId:this.form.questionTypeId,
        courseId:this.course.id
      };
      if (!parms.questionTypeId) {
        return this.$message.error(
          "只能导出单个类型题目，请选择下拉框中的试题类型"
        );
      }

      if (parms.questionTypeId === 1 || parms.questionTypeId === 3){
        this.openLoadingView();
        this.$axios_supermallData({
          method: 'get',
          url: "hnjxjy-core/eduCourseQuestion/excelEduQuestion",
          params: {
            questionTypeId:this.form.questionTypeId,
            courseId:this.course.id
          },
          responseType: 'blob'
        }).then((res) => {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          let downloadElement = document.createElement('a');
          let href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '题库.xls';　　　　　　　
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
          this.loadingView.close();
        });
      }else {
        this.$axios_supermallData({
          method: 'get',
          url: "hnjxjy-core/eduCourseQuestion/excelEduQuestionOther",
          params: {
            questionTypeId:this.form.questionTypeId,
            courseId:this.course.id
          },
          responseType: 'blob'
        }).then((res) => {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          let downloadElement = document.createElement('a');
          let href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '题库.xls';　
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
          this.loadingView.close();
        });
      }
    },
    // 批量更新题目
    setModule() {
      if (this.selectionData.length === 0) {
        return this.$message.error("请至少选择一条数据");
      }
    },
    // 清理同步
    clearSyncTitle() {
      if (this.selectionData.length === 0) {
        return this.$message.error("请至少选择一条数据");
      }
    },
    // 删除
    onDel() {
      if (this.selectionData.length === 0) {
        return this.$message.error("请至少选择一条数据");
      }
      this.$confirm("您确定要进行删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeleteEduCourseQuestion({
            ids: this.selectionData.map((m) => m.id).join(),
          }).then((res) => {
            if (res.data.code && res.data.code === 50002) {
              this.$message.warning(res.data.message);
            } else {
              this.$message.success("删除成功");
              this.query();
            }
          });
        })
        .catch(() => {});
    },
    // 停用
    onStop() {
      if (this.selectionData.length === 0) {
        return this.$message.error("请至少选择一条数据");
      }
      this._UpdateEduCourseQuestion(0);
    },
    // 启用
    onStart() {
      if (this.selectionData.length === 0) {
        return this.$message.error("请至少选择一条数据");
      }
      this._UpdateEduCourseQuestion(1);
    },
    _UpdateEduCourseQuestion(enabled) {
      let data = new FormData();
      data.append("ids", this.selectionData.map((m) => m.id).join());
      data.append("enabled", enabled);
      UpdateEduCourseQuestion(data).then((res) => {
        if (res.data && res.data.code === 2000) {
          this.query();
          return;
        }
        res.data && this.$message.error(res.data.message);
      });
    },
  },
};
</script>
