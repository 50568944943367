<style scoped lang="less">
.PreviewDialog {
  .content {
    font-size: 14px;
    .title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .radioItem {
      li {
        margin-bottom: 20px;
        div {
          display: inline-block;
        }
      }
    }
    // 参考答案
    .reference_answer {
      background-color: #f9f9f9;
      padding: 15px;
      border: 1px dashed #d8d8d8;
      overflow: hidden;
      color: #999;
      margin-top: 20px;
      .name {
        margin-bottom: 10px;
        color: #099;
      }
      .answer {
      }
    }
    .parse {
      padding: 15px;
      .name {
        margin-bottom: 10px;
        color: #099;
        font-weight: 500;
      }
    }
  }
}
</style>
<template>
  <div class="PreviewDialog">
    <div class="content" v-loading="loading">
      <!-- {{ items.title }} -->
      <div class="title" v-html="items.questionTitle"></div>
      <!-- 到时候写个逻辑判断一下题目类型，用来展示哪一类的题目。 -->
      <!-- 单选题的话，打开这个v-if -->
      <ul
        class="radioItem"
        v-if="items.eduCourseQuestionOps && items.eduCourseQuestionOps.length"
      >
        <li v-for="(op, index) in items.eduCourseQuestionOps" :key="index">
          <div class="itemKey">{{ op.itemKey }}）</div>
          <div class="itemVal">{{ op.itemVal }}</div>
        </li>
      </ul>
      <!-- 参考答案 -->
      <div class="reference_answer">
        <p class="name">参考答案</p>
        <p class="answer" v-html="items.questionKey"></p>
      </div>
      <!-- 解析 -->
      <div class="parse">
        <p class="name">解析</p>
        <p class="parse_content" v-html="items.questionAnalyze"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { QueryEduCourseQuestionById } from "@/libs/api/teacher/answer";
export default {
  name: "preview-dialog",
  props: ["id"], // 当前行的信息
  data() {
    return {
      loading: false,
      items: {},
    };
  },
  watch: {
    id(id) {
      id && this.init(id);
    },
  },
  mounted() {
    this.id && this.init(this.id);
  },
  methods: {
    init(id) {
      this.loading = true;
      QueryEduCourseQuestionById({ id })
        .then((res) => {
          this.loading = false;
          this.items = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>