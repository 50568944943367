<style scoped lang="less">
.t_match {
  ul {
    li {
      display: flex;
      justify-content: space-between;
      .t_match_left,
      .t_match_right {
        margin-bottom: 10px;
        padding: 4px 8px;
        background: #f2f2f2;
        border-radius: 2px;
        display: flex;
        align-items: center;
        .el-input {
          margin: 0 5px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.t_match {
  ul li .t_match_left {
    .el-select {
      max-width: 50px !important;
      margin-left: 10px;
      .el-input__inner {
        padding-left: 10px;
        padding-right: 5px;
      }
    }
  }
}
</style>
<template>
  <div class="t_match">
    <ul>
      <li>
        <div>
          <div
            class="t_match_left"
            v-for="(item, index) in dataLeft"
            :key="index"
          >
            <span>{{ index + 1 }}.</span>
            <el-input
              v-model="item.text"
              clearable
              :maxlength="100"
              show-word-limit
            />
            <el-button
              type="success"
              icon="el-icon-edit"
              circle
              @click="editLeft(index)"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-delete"
              circle
              @click="delLeft(index)"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-bottom"
              circle
              @click="downLeft(index)"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-top"
              circle
              @click="upLeft(index)"
            ></el-button>
            <el-select v-model="item.select">
              <el-option label="A" value="A"></el-option>
              <el-option label="B" value="B"></el-option>
              <el-option label="C" value="C"></el-option>
              <el-option label="D" value="D"></el-option>
            </el-select>
          </div>
          <el-button
            @click="addSelect"
            type="success"
            icon="el-icon-circle-plus"
            >添加选项</el-button
          >
        </div>
        <div>
          <div
            class="t_match_right"
            v-for="(item, index) in dataRight"
            :key="index"
          >
            <span>{{ mapper(index + 1) }}.</span>
            <el-input
              v-model="item.text"
              clearable
              :maxlength="100"
              show-word-limit
            />
            <el-button
              type="success"
              icon="el-icon-edit"
              circle
              @click="editRight(index)"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-delete"
              circle
              @click="delRight(index)"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-bottom"
              circle
              @click="downRight(index)"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-top"
              circle
              @click="upRight(index)"
            ></el-button>
          </div>
          <el-button
            @click="addAnswer"
            type="success"
            icon="el-icon-circle-plus"
            >添加答案</el-button
          >
        </div>
      </li>
    </ul>
    <EditorContent ref="editorLeftRef" @saveClose="saveCloseLeft" />
    <EditorContent ref="editorRightRef" @saveClose="saveCloseRight" />
  </div>
</template>

<script>
import { MAPPER } from "@/tools/const";
import EditorContent from "../EditorContent.vue";
export default {
  name: "t_match",
  components: { EditorContent },
  data() {
    return {
      dataLeft: [
        { text: null, select: "A", textRight: null },
        { text: null, select: "A", textRight: null },
        { text: null, select: "A", textRight: null },
        { text: null, select: "A", textRight: null },
      ],
      dataRight: [
        { text: null },
        { text: null },
        { text: null },
        { text: null },
      ],
    };
  },
  computed: {
    mapper() {
      return (index) => {
        let letter = "";
        for (const key in MAPPER) {
          if (index === MAPPER[key]) {
            letter = key;
            break;
          }
        }
        return letter;
      };
    },
  },
  methods: {
    addSelect() {
      this.dataLeft.push({ text: null, select: "A" });
    },
    addAnswer() {
      this.dataRight.push({ text: null });
    },
    editLeft(index) {
      this.$refs["editorLeftRef"].editItem.index = index;
      this.$refs["editorLeftRef"].editItem.dialog = true;
    },
    delLeft(index) {
      this.dataleft.splice(index, 1);
    },
    downLeft(index) {
      this.dataLeft = this.sort(this.dataLeft, index);
    },
    upLeft(index) {
      this.dataLeft = this.sort(this.dataLeft, index, true);
    },
    editRight(index) {
      this.$refs["editorRightRef"].editItem.index = index;
      this.$refs["editorRightRef"].editItem.dialog = true;
    },
    delRight(index) {
      this.dataRight.splice(index, 1);
    },
    downRight(index) {
      this.dataRight = this.sort(this.dataRight, index);
    },
    upRight(index) {
      this.dataRight = this.sort(this.dataRight, index, true);
    },
    saveCloseLeft({ index, content }) {
      this.dataLeft[index].text = content;
      this.$emit("options", this.dataLeft);
    },
    saveCloseRight({ index, content }) {
      this.dataRight[index].text = content;
      this.$emit("options", this.dataLeft);
    },
    // 升降序
    sort(data, index, up) {
      const item = data[index];
      const isUp = up ? index - 1 : index + 1;
      const down = data[isUp];
      if (down) {
        this.$set(data, index, down);
        this.$set(data, isUp, item);
      }
      this.$emit("options", data);
      return data;
    },
  },
};
</script>
