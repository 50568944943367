<template>
  <div class="t_editor_content">
    <el-dialog
      :visible.sync="editItem.dialog"
      width="40%"
      title="编辑题目选项"
      append-to-body
    >
      <QuillEditor
        style="margin-top: 0"
        @uploadFile1="uploadFile1"
        :resultMethods="'uploadFile1'"
        :paramsText="editItem.content"
        @quillEditorHtml="editItemEditorHtml"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editSaveClose">保存后关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QuillEditor from "@/components/teacher/QuillEditor.vue";
export default {
  name: "t_editor_content",
  components: { QuillEditor },
  props: {
    options: Array,
  },
  data() {
    return {
      editItem: {
        dialog: false,
        content: "",
        index: null,
      },
    };
  },
  methods: {
    uploadFile1(str){
      if (null == this.editItem.content){
        this.editItem.content = str;
      }else {
        this.editItem.content = this.editItem.content+str;
      }
    },
    editItemEditorHtml(html) {
      this.editItem.content = html;
    },
    // 保存后关闭
    editSaveClose() {
      const { index, content } = this.editItem;
      this.editItem.dialog = false;
      this.$emit("saveClose", { index, content });
    },
  },
};
</script>

<style scoped lang="less">
.t_editor_content {
}
</style>
