import request from '../../request'
import qs from 'qs'

//  题库管理 - 分页模糊查询
export const QueryEduCourseByTeaId = (p) => {
    return request.get(`eduCourseQuestion/queryEduCourseByTeaId?${qs.stringify(p)}`).then(res => res.data);
}
//  题库管理 - 添加(单选，多选，填空，问答，判断)
export const AddEudQuestionAll = (data) => {
    return request.post(`eduCourseQuestion/addEudQuestionAll`, data).then(res => res.data);
}
//  题库管理 - 题库管理根据题目id查询选项 （预览）
export const QueryEduCourseQuestionById = (p) => {
    // eduCourseQuestion/queryEduCourseByQuestionId
    return request.get(`eduCourseQuestion/queryEduCourseQuestionById?${qs.stringify(p)}`).then(res => res.data);
}
//  题库管理 - 批量删除题目
export const DeleteEduCourseQuestion = (p) => {
    return request.get(`eduCourseQuestion/deleteEduCourseQuestion?${qs.stringify(p)}`).then(res => res.data);
}
//  题库管理 - 导出题目(单选或者多选) 题目类型id - 1单选题  3多选题
export const ExcelEduQuestion = (p) => {
    return request({
        url: `eduCourseQuestion/excelEduQuestion?${qs.stringify(p)}`,
        responseType: 'blob'
    }).then(res => res.data);
}
//  题库管理 - 导出题目(其他类型，不包括有子题目的题型)
export const ExcelEduQuestionOther = (p) => {
    return request({
        url: `eduCourseQuestion/excelEduQuestionOther?${qs.stringify(p)}`,
        responseType: 'blob'
    }).then(res => res.data);
}
//  题库管理 - 设置禁用或者开启
export const UpdateEduCourseQuestion = (data) => {
    return request.post(`eduCourseQuestion/updateEduCourseQuestion`, data).then(res => res.data);
}
//  题库管理 - 根据问题id编辑题目(单选，多选，问答，填空，判断)
export const UpdateEduCourseQuestionById = (data) => {
    return request.post(`eduCourseQuestion/updateEduCourseQuestionById`, data).then(res => res.data);
}
//  题库管理 - 导入题目模板下载
export const ExportMB = (p) => {
    return request({
        url: `eduCourseQuestion/exportMB?${qs.stringify(p)}`,
        responseType: 'blob'
    }).then(res => res.data);
}