
<style lang="less">
.t_answer_AddTitleDialog {
  .t_answer_AddTitleDialog_content {
    overflow-y: auto;
    max-height: 520px;
    padding-right: 10px;
  }
  .quillEditor {
    margin-top: 5px;
  }
}
</style>
<template>
  <div>
    <el-dialog
      :visible.sync="dialog"
      :title="`题目${rows ? '编辑' : '新增'}-${items ? items.label : ''}`"
      custom-class="t_answer_AddTitleDialog"
      width="70%"
    >
      <div class="t_answer_AddTitleDialog_content">
        <el-form :model="form" size="medium" label-width="60px">
          <el-form-item label="难度" style="width: 50%; display: inline-block">
            <el-select
              v-model="form.difficultyVal"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in 10"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="题干" required>
            <QuillEditor
              class="quillEditor"
              @uploadFile1="uploadFile1"
              :resultMethods="'uploadFile1'"
              @quillEditorHtml="questionTitleHTML"
              :paramsText="
                keys === '4' ? questionTitle.join('\t') : form.questionTitle
              "
            />
          </el-form-item>
          <el-form-item label="选项" v-if="selectFormItem" required>
            <!-- 单选，多选 -->
            <t-checked
              :eduCourseQuestionOps="eduCourseQuestionOps"
              :questionKey="form.questionKey"
              :items="items"
              @options="handleOptions"
              v-if="keys === '1' || keys === '3'"
            />
          </el-form-item>
          <el-form-item
            label="答案"
            v-if="answerFormItem"
            :required="answerRequried(keys)"
          >
            <!-- 判断题 -->
            <el-radio-group v-model="form.questionKey" v-if="keys === '2'">
              <el-radio label="1">对</el-radio>
              <el-radio label="2">错</el-radio>
            </el-radio-group>
            <!-- 填空题 -->
            <TFill
                ref="fill1"
              v-if="keys === '4'"
              :questionKey="form.questionKey"
              @title_fill="title_fill"
              @options="handleOptions"
              @optionsInput="optionsInput"
            />
            <!-- 问答题 -->
            <QuillEditor
              v-if="keys === '5'"
              style="margin-top: 0"
              :paramsText="form.questionKey"
              @uploadFile3="uploadFile3"
              :resultMethods="'uploadFile3'"
              @quillEditorHtml="answerEditorHtml"
            />
          </el-form-item>

          <el-form-item label="题目解析" required>
            <QuillEditor
              class="quillEditor"
              @uploadFile2="uploadFile2"
              :resultMethods="'uploadFile2'"
              :paramsText="form.questionAnalyze"
              @quillEditorHtml="answerJxEditorHtml"
            />
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button  @click="dialogFalse">取 消</el-button>
        <el-button type="primary" @click="confirm">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QuillEditor from "@/components/teacher/QuillEditor.vue";
import TChecked from "./title_module/t_checked.vue";
import TFill from "./title_module/t_fill.vue";
import TMatch from "./title_module/t_match.vue";
import { MAPPER } from "@/tools/const";
import {
  QueryEduCourseQuestionById,
  UpdateEduCourseQuestionById,
} from "@/libs/api/teacher/answer";
export default {
  name: "t_answer_AddTitleDialog",
  components: { QuillEditor, TChecked, TFill, TMatch },
  props: {
    items: { type: Object, default: { label: "1" } },
    rows: Object,
  },
  data() {
    return {
      dialog: false,
      form: {
        difficultyVal: "1", // 难度
        questionTitle: "", // 题干
        questionKey: "", // 答案
        questionAnalyze: null, // 题目解析
      },
      questionTitle: [], // 填空的题干
      fillInputData: [], // 填空题输入
      json: null, // 单选多选题的选项
      eduCourseQuestionOps: [], // 单选多选题选项 用于编辑
      // 判断题
      judge: "1",
    };
  },
  watch: {
    rows(n) {
      if (n) {
        this.init({ id: n.id });
      } else {
        this.form = {
          difficultyVal: "1", // 难度
          questionTitle: "", // 题干
          questionKey: "", // 答案
          questionAnalyze: null, // 题目解析
        };
        this.eduCourseQuestionOps = [];
        // 填空题题干
        this.questionTitle = [];
      }
    },
  },
  computed: {
    mapper() {
      return (index) => {
        let letter = "";
        for (const key in MAPPER) {
          if (index === MAPPER[key]) {
            letter = key;
            break;
          }
        }
        return letter;
      };
    },
    keys() {
      return this.items ? this.items.key : null;
    },
    // (1单选题，2多选题，3判断题，4填空题，5问答题)
    // 选项
    selectFormItem() {
      if (this.keys === "1" || this.keys === "3") {
        return true;
      }
      return false;
    },
    // 答案
    answerFormItem() {
      if (this.keys === "2" || this.keys === "4" || this.keys === "5") {
        return true;
      }
      return false;
    },
    // 答案是否必填
    answerRequried() {
      return (key) => {
        if (key === "2" || key === "5") return true;

        return false;
      };
    },
  },

  methods: {
    uploadFile2(str){
      if (null == this.form.questionAnalyze){
        this.form.questionAnalyze = str
      }else {
        this.form.questionAnalyze = this.form.questionAnalyze+str;
      }
    },
    uploadFile1(str){
      if (null == this.form.questionTitle){
        this.form.questionTitle = str
      }else {
        this.form.questionTitle = this.form.questionTitle+str;
      }
    },
    uploadFile3(str){
      if (null == this.form.questionKey){
        this.form.questionKey = str
      }else {
        this.form.questionKey = this.form.questionKey+str;
      }
    },
    // 查询详情
    init(data) {
      QueryEduCourseQuestionById(data).then((res) => {
        const { data } = res;
        this.form = {
          ...data,
        };

        const questionTypeId = data.questionTypeId;
        // 单选题 多选题
        if (questionTypeId === 1 || questionTypeId === 3) {
          this.eduCourseQuestionOps = data.eduCourseQuestionOps;
        } else if (questionTypeId === 4) {
          // 填空题
          this.questionTitle = [data.questionTitle];
          this.fillInputData = data.questionKey.split(",");
        } else if (questionTypeId === 2) {
          // 判断题
          this.form.questionKey =
            data.questionKey.indexOf("错") > -1 ? "2" : "1";
        }
      });
    },
    confirm() {
      const { difficultyVal, questionTitle, questionAnalyze, questionKey } =
        this.form;
      if (!difficultyVal) return this.$message.error("请选择难度");
      if (!questionTitle) return this.$message.error("请输入题干");
      if (
        (this.keys === "4") &
        (!this.fillInputData.length ||
          this.fillInputData.filter((f) => f.split(".")[1] === "").length)
      ) {
        return this.$message.error("答案不能为空");
      }
      if ((this.keys !== "4") & !questionKey) {
        return this.$message.error("答案不能为空");
      }


      if (!this.rows && (this.keys === "1" || this.keys === "3")) {
        const json = this.json ? JSON.parse(this.json) : null;
        if (!json) {
          return this.$message.error("请输入题目选项");
        }
        for (const key in json) {
          const item = json[key];
          if (!item) {
            return this.$message.error("请输入题目选项");
          }
        }
      }
      if (!questionAnalyze) return this.$message.error("请输入题目解析");
      // 编辑
      if (this.rows) {
        const { id, eduCourseQuestionOps } = this.form;
        let data = {
          id,
          difficultyVal,
          questionTitle,
          questionAnalyze,
          questionKey,
        };
        if (this.keys === "1" || this.keys === "3") {
          if (!this.json) {
            this.json = {};
            eduCourseQuestionOps.forEach((v) => {
              this.json[v.itemKey] = v.itemVal;
            });
            this.json = JSON.stringify(this.json);
          }
          const json = JSON.parse(this.json);
          for (const key in json) {
            if (!json[key]) return this.$message.error("请输入题目选项");
          }
          data.json = this.json;
        }
        this.openLoadingView();
        UpdateEduCourseQuestionById(data).then((res) => {
          if (res.data.code && res.data.code === 50002) {
            return this.$message.warning(res.data.message);
          }
          this.$message.success("更新题目成功");
          this.dialog = false;
          this.$emit("initQuery");
          this.loadingView.close();
        })
        .catch(err => {
          this.loadingView.close();
        })
      } else {
        // 添加
        this.$emit(
          "onInputData",
          this.keys === "1" || this.keys === "3"
            ? { ...this.form, json: this.json }
            : this.form,
          this.fillInputData
        );
      }
    },
    // 渲染解析
    answerJxEditorHtml(html) {
      this.form.questionAnalyze = html;
    },
    // 渲染题干 （填空题除外）
    questionTitleHTML(html) {
      if (this.key !== "4") {
        this.form.questionTitle = html;
      }
    },
    // 填空题 - 渲染题干
    title_fill({ text, index }) {
      if (text) {
        this.questionTitle.push(
          `<p><span class="ql-size-large" style="background-color: #000; color: rgb(255, 255, 255);">${text}</span></p>`
        );
        return;
      }
      this.questionTitle.splice(index, 1);
    },
    // 单选题 多选题 填空题 答案
    handleOptions(data) {
      const key = this.keys;
      // 单选
      if (key === "1") {
        const index = data.findIndex((f) => f.checked);
        if (index >= 0) {
          // 答案
          this.form.questionKey = this.mapper(index + 1);
        }
        this.checkedOptions(data);
      } else if (key === "3") {
        // 多选
        let answer = [];
        data.forEach((f, i) => {
          if (f.checked === true) answer.push(this.mapper(i + 1));
        });
        // 答案
        this.form.questionKey = answer.join();
        this.checkedOptions(data);
      } else if (key === "4") {
        // 填空题
        let anwser = [];
        data.forEach((m, i) => {
          anwser.push(`${i + 1}.${m.text}`);
        });
        // this.form.questionKey = data.map((m) => m.text).join();
        this.form.questionKey = anwser.join();
      }
    },
    optionsInput(data) {
      let anwser = [];
      data.forEach((m, i) => {
        anwser.push(`${i + 1}.${m.text}`);
      });
      this.fillInputData = anwser;
    },
    // 问答题答案
    answerEditorHtml(html) {
      this.form.questionKey = html;
    },

    // 选项
    checkedOptions(data) {
      const len = data.length;
      let obj = {};
      for (const key in MAPPER) {
        for (let i = 0; i < len; i++) {
          if (i + 1 === MAPPER[key]) {
            obj[key] = data[i].text;
            continue;
          }
        }
      }
      this.json = JSON.stringify(obj);
    },
    dialogFalse(){
      this.form = {
        difficultyVal: "1", // 难度
        questionTitle: "", // 题干
        questionKey: "", // 答案
        questionAnalyze: null, // 题目解析
      };
      if (this.$refs.fill1){
        this.$refs.fill1.data = [];
      }
      this.fillInputData = [];
      this.questionTitle = [];
      this.json = null;
      this.eduCourseQuestionOps = [];
      this.dialog = false;
    },
  },
};
</script>
